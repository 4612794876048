type ObjectWithKey = { key: string };
type ObjectWithName = { name: string };

/**
 * Compare function to sort strings in alphabetical order
 */
export const alphabetical = (a: string, b: string) => a.localeCompare(b);

/**
 * Compare function to sort strings in alphabetical order
 */
export const reverseAlphabetical = (a: string, b: string) => b.localeCompare(a);

/**
 * Compare function to sort numbers in numerical order
 */
export const numerical = (a: number, b: number) => a - b;

/**
 * Compare function to sort objects in alphabetical order by key
 */
export const alphabeticalByKey = (a: ObjectWithKey, b: ObjectWithKey) => a.key.localeCompare(b.key);

/**
 * Compare function to sort objects in alphabetical order by name
 */
export const alphabeticalByName = (a: ObjectWithName, b: ObjectWithName) =>
  a.name.toLowerCase().localeCompare(b.name.toLowerCase());

/**
 * Compare function to sort objects in alphabetical order by name, with 'Other' at the end
 */
export const alphabeticalByNameExceptOther = (a: ObjectWithName, b: ObjectWithName) => {
  if (a.name.toLowerCase() === 'other') {
    return 1;
  }
  if (b.name.toLowerCase() === 'other') {
    return -1;
  }
  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
};
